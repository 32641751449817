.section.-introduction{
	position: relative;
	padding-top: 0;
	padding-bottom: 0;
	max-width: 1200px;
}

.introduction{
	padding: 3rem 0rem 2rem;
	background-color: rgba(#ffffff, .6);
	border-radius: 2rem;
	@include min(600px){
		backdrop-filter: blur(5px);
		padding: 5.4rem 4rem 4rem;
	}
	.text-component{
		margin-bottom: 0;
		h3{
			font-size: rem(36px);
			margin-bottom: 2rem;
		}
	}
}
