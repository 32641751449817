.home {
	position: relative;
	z-index: 40;
	display: flex;
	flex-direction: column;
	@include min(600px){
		margin-top: -150px;
	}
	@include min(800px){
		&::before{
			position: absolute;
			content: "";
			right: 5vw;
			top: 250px;
			max-width: 290px;
			background: transparent url(/images/local/icons/v-202411080924/homepage-tiles-arrows.svg) no-repeat 100% 0 / contain;
			width: 330px;
			height: 330px;
			z-index: -1;
		}
	}
	@include min(1360px){
		margin-top: -250px;
	}
}

.section {
	padding: 1rem 0;

	.element-type-heading {
		font-size: rem(36px);
		color: #292A2D;
		text-align: center;
		margin-bottom: 1em;
	}

	@include min(800px) {
		padding: 0 0 2rem;
	}

	@include min($max-width) {
		padding: 0.1rem 0 4rem;
	}
}

.anchor{
	position: relative;
	top: -20px;
	@include min(1024px){
		top: -140px;
	}
}

.homepage__anchors{
	position: fixed;
	top: 50%;
	transform: translate(100%, -50%);
	right: 0;
	z-index: 50;
	background-color: $color-brand;
	color: #fff;
	padding: 3rem 3.6rem 2.5rem;
	transition: transform .3s ease-in-out;
	display: none;
	@include min(600px){
		display: block;
	}
	.list{
		line-height: 1.6;
		&__item{
			margin-bottom: 0;
			&.-go-to-head{
				position: absolute;
				top: 0;
				transform: translateY(-50%);
				margin-left: 0.4rem;
				.list__link{
					background-color: $color-light;
					width: 40px;
					height: 40px;
					@include flex(df, aic, jcc);
					border-radius: 12px;
					box-shadow: 0 0 15px rgba( #000000, .5);
					&::before{
						content: url(/images/local/icons/v-202411080924/anchor-arrow.png);
					}
				}
			}
		}
		&__link{
			text-decoration: none;
			&.active{
				font-weight: 700;
			}
			@include link-over(&){
				text-decoration: underline;
			}
		}
	}
	@include link-over(&){
		transform: translate(0%, -50%);
		.homepage__anchors-trigger{
			&::before{
				transform: rotate(0deg);
			}
		}
	}
	@include link(){
		color: #fff;
	}
	&-trigger{
		position: absolute;
		@include flex(df, aic, jcc);
		right: 100%;
		top: 50%;
		width: 50px;
		height: 93px;
		transform: translateY(-50%);
		background: transparent url(/images/local/icons/v-202411080924/anchor-left-bubble.png) no-repeat center center;
		&::before{
			transform: rotate(180deg);
			content: url(/images/local/icons/v-202411080924/anchor-arrow-w.png);
			transition: transform .3s ease-in-out;
		}
	}
}


.section.-news{
	padding-top: 0.5rem;
	h2.element-type-heading{
		color: $color-brand;
	}
}

.section.-chosen{
	position: relative;
	padding-top: 0.5rem;
	&::before{
		position: absolute;
		content: "";
		top: -15%;
		right: 3%;
		width: 347px;
		height: 171px;
		display: inline-block;
		background: transparent url(/images/local/v-202411080924/zpravodaj-bg.svg) no-repeat center center / contain;
		z-index: -1;
	}
}

.section.-discovery{
	position: relative;
	z-index: 20;
	padding-top: 6.3rem;
	padding-bottom: 4rem;
	h2.element-type-heading{
		font-size: rem(36px);
		color: $color-light;
		margin-bottom: 0.65em;
	}
	.discovery{
		position: relative;
		color: $color-light;
		font-size: rem(19px);
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem 0;

		&-block{
			position: relative;
			background-color: $color-brand;
			padding: 2rem 4% 0.5rem;
			border-radius: 28px;
			&.-edeska{
				display: flex;
				align-items: center;
				text-align: center;
				background: $color-secondary url(/images/local/v-202411080924/board-bg.svg) no-repeat 100% 100%;
				background-size: 14rem;
				padding-top: 1.75rem;
				padding-bottom: 1.75rem;
				.dev-element-block{
					flex-grow: 1;
				}
				h2.element-type-heading{
					max-width: 9em;
					margin: 0 auto 2rem;
				}
				.list__item{
					display: flex;
					justify-content: center;
				}
				@include link(){
					color: $color-secondary;
					background-color: $color-light;
				}
				&::after{
					content: normal;
				}
				@include min(1024px){
					background-position: 130% 120%;
					background-size: 22rem;
				}
			}
		}
		@include min(800px){
			&-block{
				padding: 4.5rem 6rem 1.75rem;
				&::after{
					position: absolute;
					left: 50%;
					top: 0%;
					content: "";
					transform: translate(-50%, -50%);
					width: 129px;
					height: 111px;
					background: transparent url(/images/local/v-202411080924/arrows-w.svg) no-repeat center center;

				}
			}
		}
		@include min(1024px){
			grid-template-columns: 1.4fr 1fr;
			gap: 4rem;
		}
		@include min(1300px){
			gap: 8rem;
		}
	}
	.discovery__more-button{
		background-color: $color-secondary;
		@include link-over(&) {
			text-decoration: underline;
		}
	}
}

.section.-board{
	position: relative;
	padding-top: 3rem;
	&::before,
	&::after{
		position: absolute;
		content: "";
	}
	@include min(800px){
		padding-top: 7.4rem;
		&::before{
			top: -45px;
			left: 0;
			width: 268px;
			height: 224px;
			background: transparent url(/images/local/v-202411080924/board-bg.svg) no-repeat center center;
			@include min(1360px){
				top: -135px;
				left: 7.5%;
				width: 328px;
				height: 334px;
			}
		}
		&::after{
			top: 50%;
			right: 0;
			width: 327px;
			height: 491px;
			background: transparent url(/images/local/v-202411080924/books.png) no-repeat center center;
			transform: translateY(-50%);
		}
	}

	.centered{
		position: relative;
		z-index: 20;
		max-width: 1200px;
	}
}
