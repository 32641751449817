.card {
	background-color: #ffffff;
	padding: 0;
	position: relative;
	transition: background-color .2s ease;

	&:hover {
		background-color: #ffffff;
		.card__date-folder{
			border-color: $color-dark;
		}
	}

	&:focus-within {
		background-color: #ffffff;
	}

	.board &{
		padding: 2rem 1.35rem 2.4rem;
		height: 100%;
		background-color: #f5f5f5;
		@include min(600px){
			background-color: #ffffff;
		}
	}

	&__image {
		position: relative;
		margin: 0 0 .85em;
		max-width: 100%;
		height: 0;
		padding-bottom: ratio(3,2);
		width: 100%;
		overflow: hidden;
		border-radius: 12px;
		&:empty {
			background: #f5f5f5 encode-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 173 147.1" width="191" height="162" xml:space="preserve" fill="#e9e9e9"><path d="M151.2 26.4l8-8.2-6.2-12-10.5 9.4c-.3-3.8-1.2-8.2-4.4-15.6h-.1c-6 2.3-6.7 1.7-10.3 3.6 2.8 11.6 1.2 18.7-2.6 29.3l-7.8 7.8c-.4-3.8-1.8-8.5-4.9-15.7-6 2.3-6.3 1.4-9.9 3.3-.6 10.4.8 19.5-3 30.2l-13 13-13-13c-3.8-10.7-2.3-19.8-3-30.2-3.6-1.9-3.9-1-9.9-3.3-3.1 7.2-4.5 11.8-4.9 15.7l-7.8-7.8c-3.8-10.6-5.3-17.7-2.6-29.3C41.7 1.7 41 2.3 35 0h-.1c-3.2 7.4-4.2 11.8-4.4 15.6L20 6.2l-6.2 12 8 8.2c-5.2.2-15.9.1-21.8 3.2 3.5 5.5 3.2 6.4 5.1 10.1 10.4.6 18.8-1.2 29.4 2.6l8.1 8.1c-4.2.7-9.3 2.6-16.9 5.9 2.3 6 .9 4.8 2.8 8.4 10.4.6 20.9-.7 31.6 3.1l15.1 15.1-30.8 30.9c-.4-4.2-1.9-9.3-5.1-16.8-6 2.3-6.4 3.2-10.1 5.1-.6 10.4 1.2 18.8-2.6 29.4l-5.1 5.1c4 4.9 8.1 9 12.8 10.5l5.7-6.5c10.7-3.8 18.8-2.9 29.2-3.6 1.9-3.6 3.1-3.1 5.4-9.1-7.5-3.2-12.5-4.7-16.8-5.1l28.7-28.7 28.7 28.7c-4.2.4-9.3 1.9-16.8 5.1 2.3 6 3.5 5.5 5.4 9.1 10.4.6 18.5-.2 29.2 3.6l5.7 6.5c4.6-1.5 8.8-5.6 12.8-10.5l-5.1-5.1c-3.8-10.7-2-19.1-2.6-29.4-3.6-1.9-4-2.8-10.1-5.1-3.2 7.5-4.7 12.6-5.1 16.8L97.8 82.9l15.1-15.1c10.7-3.8 21.2-2.5 31.6-3.1 1.9-3.6.5-2.4 2.8-8.4-7.6-3.3-12.7-5.3-16.9-5.9l8.1-8.1c10.7-3.8 19.1-2 29.4-2.6 1.9-3.6 1.6-4.5 5.1-10.1-5.9-3.1-16.5-3-21.8-3.2z"/></svg>') no-repeat center center;
			transition: background-color .3s ease-in-out;
		}

		img,
		svg {
			@include fill;
			height: 100%;
			width: 100%;
			object-fit: cover;
			object-position: center;
			transition: transform .3s ease-in-out;
			transform: scale(1);
		}
	}

	&__title {
		margin-top: 1.5rem;
		line-height: 1.25;
		margin-bottom: .75em;
		font-size: rem(17px);
		font-weight: 500;
		color: inherit;
		padding-left: 1rem;
		padding-right: 1rem;
		.board &{
			margin-top: 0.25rem;
		}
	}

	&__description {
		font-size: rem(12px);
		line-height: 1.5;
		margin-top: 1.2rem;
		padding-left: 1rem;
		padding-right: 1rem;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 4;
	}

	&__date-folder{
		position: relative;
		z-index: 25;
		display: flex;
		flex-wrap: wrap;
		align-items: baseline;
		font-size: rem(12px);
		font-weight: bold;
		border-bottom: 1px solid #f2f3ef;
		margin: 0;
		padding-left: 1rem;
		padding-right: 1rem;
		@include link(){
			color: $color-brand;
		}

		>*:not(:last-child){
			&::after{
				content: "\2022";
				margin: 0 0.5rem;
				color: $color-base;
			}
		}
		.board &{
			border-bottom: none;
		}
	}

	&__dates{
		@include flex(df, fww);
		align-items: center;
		.card__date{
			margin: 0;
			&:not(:last-child){
				&::after{
					content: " - ";
					margin: 0 0.5rem;
				}
			}
			&::before{
				content: normal!important;
			}
		}
	}

	&__date {
		margin: 0.2em 0 1em 0;
		font-weight: bold;
		display: flex;
		align-items: center;
		color: $color-brand;
	}

	&__link {
		&.-cover-link {
			&::after {
				@include fill;
				content: "";
			}
		}

		&:hover,
		&:focus {
			color: inherit;
		}
	}

	.info-hidden,
	.info-concept {
		display: inline-flex;

		&::before {
			top: 1px;
		}
	}
	@include min(1024px){
		.card__date-folder,
		.card__title,
		.card__description{
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}
	}
}

@include link(".card__link") {
	color: $color-base;
	text-decoration: underline;
}

@include link-over(".card__link") {
	color: $color-brand;
	text-decoration: none;
	.card__image{
		&:empty{
			background-color: #bdbdbd;
		}
		img,
		svg {
			transform: scale(1.1);
		}
	}
}
