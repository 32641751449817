.section.-homepage-tiles{
	position: relative;
	z-index: 20;
	padding-top: 1.8rem;
}

.homepage-tiles{
	position: relative;
	.list{
		@include grid(1px 1 1rem, 400px 2, 600px 3, 1024px 6 1.75rem);
		&__item{
			position: relative;
			background-color: rgba(#ffffff, .6);
			backdrop-filter: blur(5px);
			border: 2px solid #e4e4e4;
			text-align: center;
			border-radius: 12px;
			padding: 1rem 1rem 1.3rem;
			margin-bottom: 0;
		}
	}
	//kvůli administraci
	.vismo-left-buttons-together li[data-group-element-id] {
		&::before {
			content: normal !important;
		}

		.vismo-checkbox,
		.vismo-context-button {
			z-index: 30;
			left: 0 !important;
		}

		.vismo-checkbox {
			top: 1.75rem;
			background-color: #eee;
			width: 20px;
			padding: 0 .3rem .2rem;
			box-sizing: content-box;
			border-radius: 0 0 5px 5px;
			font-size: 1rem;
		}
	}
}

.homepage-tile{
	&__image{
		margin: 0 0 0;
		svg,
		img{
			max-width: 45px;
			fill: #bababa;
		}
	}
	&__title{
		font-size: rem(17px);
		color: inherit;
	}
	&__description{
		margin: 0;
		color: #656565;
		font-size: rem(12px);
	}
	&__link{
		display: flex;
		flex-direction: column;
		&::before{
			content: "";
			@include fill();
		}
		@include link(&){
			color: #252c10;
			text-decoration: none;
		}
		@include link-over(&){
			color: $color-brand;
			svg{
				fill: $color-brand;
			}
		}
	}
}
