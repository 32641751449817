@include max(599px){
	.section.-news,
	.section.-homepage-events[data-liteslider-js] {
		.list {
			transform: translateX(0)!important;
		}
		.news__buttons,
		.homepage-events__buttons{
			display: none;
		}
	}
}

@include min(600px){
	.section.-news,
	.section.-homepage-events{
		width: 100%;
		max-width: calc(100% - 100px);
    	margin: 0 auto;
		.centered,
		.dev-element-block{
			position: relative;
			z-index: 20;
		}
		.news,
		.homepage-events {
			overflow: auto;
			&__buttons{
				position: absolute;
				@include flex(df, aic, jcsb);
				top: 33%;
				transform: translateY(-50%);
				left: -3rem;
				right: -3rem;
				.liteslider-arrow-disabled{
					opacity: .25;
				}
			}
		}
		.homepage-events__buttons{
			top: 50%;
			//filter: brightness(0) invert(1);
		}

		.list {
			overflow: visible;
			white-space: nowrap;
			display: flex;
			align-items: stretch;
			margin: 0 -1.5rem;

			li {
				box-sizing: border-box;
				display: block;
				line-height: 1.1;
				white-space: normal;
				flex-shrink: 0;
				width: 100%;
				padding: 0 1.5rem;
				margin: 0;
				position: relative;

				@include min(700px) {
					width: 50%;
				}

				@include min(1000px) {
					width: calc(100% / 4);
				}
			}
		}

		&[data-liteslider-js] {
			.news,
			.homepage-events {
				overflow: hidden;
			}

			.list {
				transform: translateX(0);
				transition: transform 0.5s cubic-bezier(0.61, 0.43, 0.15, 0.94);
			}
		}
	}
}

