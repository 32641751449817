.warning-container{
	@include min(600px){
		position: absolute;
		bottom: 8rem;
		right: 0;
		z-index: 60;
		max-width: 33rem;
		padding: 2% 4% 2%;
		background-color: #fff;
	}
}

.warning{
	&__link{

	}
	&__title{
		display: inline;
		font-size: rem(16px);
	}
	&__description{
		font-size: rem(14px);
		margin: 0;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}
}
