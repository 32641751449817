@charset "UTF-8";
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/
/*
* BASE DEPENDENCIES
*/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/*==============================
	DEFAULTS
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	TABS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*==============================
	CALENDAR -PAGE
==============================*/
@keyframes fade-into-foreground {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes fade-into-background {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.75);
    opacity: 0;
  }
}
@keyframes pop-into-foreground {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes pop-into-foreground-with-bounce {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  72% {
    transform: scale(1.07);
    opacity: 0.72;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
  }
}
/*
* COMMON BLOCKS
*/
.homepage-tiles-discovery-wrapper {
  background: #ffffff url(/images/local/v-202411080924/homepage-events-bg.jpg) no-repeat center top/cover;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

.homepage-events .list {
  padding-top: 2rem;
}
@media screen and (min-width: 600px) {
  .homepage-events .list {
    margin: 0;
  }
}

.homepage-event {
  position: relative;
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  padding: 0;
  height: 100%;
  transition: box-shadow 0.3s ease-in-out;
}
.homepage-event__image {
  position: relative;
  margin: 0 0;
  overflow: hidden;
  height: 0;
  padding-bottom: 140%;
}
.homepage-event__image:empty {
  transition: background-color 0.4s ease-in-out;
  background: #f5f5f5 url("data:image/svg+xml;utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 173 147.1' width='191' height='162' xml:space='preserve' fill='%23e9e9e9'%3E%3Cpath d='M151.2 26.4l8-8.2-6.2-12-10.5 9.4c-.3-3.8-1.2-8.2-4.4-15.6h-.1c-6 2.3-6.7 1.7-10.3 3.6 2.8 11.6 1.2 18.7-2.6 29.3l-7.8 7.8c-.4-3.8-1.8-8.5-4.9-15.7-6 2.3-6.3 1.4-9.9 3.3-.6 10.4.8 19.5-3 30.2l-13 13-13-13c-3.8-10.7-2.3-19.8-3-30.2-3.6-1.9-3.9-1-9.9-3.3-3.1 7.2-4.5 11.8-4.9 15.7l-7.8-7.8c-3.8-10.6-5.3-17.7-2.6-29.3C41.7 1.7 41 2.3 35 0h-.1c-3.2 7.4-4.2 11.8-4.4 15.6L20 6.2l-6.2 12 8 8.2c-5.2.2-15.9.1-21.8 3.2 3.5 5.5 3.2 6.4 5.1 10.1 10.4.6 18.8-1.2 29.4 2.6l8.1 8.1c-4.2.7-9.3 2.6-16.9 5.9 2.3 6 .9 4.8 2.8 8.4 10.4.6 20.9-.7 31.6 3.1l15.1 15.1-30.8 30.9c-.4-4.2-1.9-9.3-5.1-16.8-6 2.3-6.4 3.2-10.1 5.1-.6 10.4 1.2 18.8-2.6 29.4l-5.1 5.1c4 4.9 8.1 9 12.8 10.5l5.7-6.5c10.7-3.8 18.8-2.9 29.2-3.6 1.9-3.6 3.1-3.1 5.4-9.1-7.5-3.2-12.5-4.7-16.8-5.1l28.7-28.7 28.7 28.7c-4.2.4-9.3 1.9-16.8 5.1 2.3 6 3.5 5.5 5.4 9.1 10.4.6 18.5-.2 29.2 3.6l5.7 6.5c4.6-1.5 8.8-5.6 12.8-10.5l-5.1-5.1c-3.8-10.7-2-19.1-2.6-29.4-3.6-1.9-4-2.8-10.1-5.1-3.2 7.5-4.7 12.6-5.1 16.8L97.8 82.9l15.1-15.1c10.7-3.8 21.2-2.5 31.6-3.1 1.9-3.6.5-2.4 2.8-8.4-7.6-3.3-12.7-5.3-16.9-5.9l8.1-8.1c10.7-3.8 19.1-2 29.4-2.6 1.9-3.6 1.6-4.5 5.1-10.1-5.9-3.1-16.5-3-21.8-3.2z'/%3E%3C/svg%3E") no-repeat center center;
}
.homepage-event__image:empty:hover, .homepage-event__image:empty:focus, .homepage-event__image:empty:active {
  background-color: gray;
}
.homepage-event__image img {
  position: absolute;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease-in-out;
  transform: scale(1);
}
.homepage-event__body {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.homepage-event__date-fullday {
  position: absolute;
  z-index: 20;
  top: 2rem;
  left: 2rem;
  max-width: calc(100% - 4rem);
  font-size: 1.125rem;
  font-weight: 700;
  padding: 0.95rem 1.5rem;
  border-radius: 0.5rem;
  background-color: #d90014;
  color: #ffffff;
}
.homepage-event__link {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
.homepage-event__link.-cover-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}
.homepage-event__link, .homepage-event__link:link, .homepage-event__link:visited {
  color: #ffffff;
}
.homepage-event__link:hover .homepage-event__title, .homepage-event__link:focus .homepage-event__title, .homepage-event__link:active .homepage-event__title {
  text-decoration: underline;
}
.homepage-event__title {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 105px;
  z-index: 15;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem 1rem;
  font-size: 1.0625rem;
  margin: 0 0 0;
  font-weight: 700;
  color: inherit;
  text-align: center;
}
.homepage-event__title::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent url(/images/local/v-202411080924/homepage-events-shadow.png) repeat-x 0 0;
  top: -5rem;
  z-index: -1;
}
.homepage-event__description {
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.homepage-event .event__status {
  padding: 0 6%;
}
.homepage-event__types {
  position: relative;
  z-index: 20;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0 1.5rem;
}
.homepage-event__type {
  display: inline-block;
}
.homepage-event__type__link {
  font-size: 0.75rem;
  font-weight: 700;
}
.homepage-event__type__link, .homepage-event__type__link:link, .homepage-event__type__link:visited {
  color: #656565;
}
.homepage-event:hover, .homepage-event:focus, .homepage-event:active {
  box-shadow: 0px 20px 10px -20px #111;
}
.homepage-event:hover .homepage-event__image img, .homepage-event:focus .homepage-event__image img, .homepage-event:active .homepage-event__image img {
  transform: scale(1.1);
}

/*
* DESIGN COMPONENTS
*/
.section.-introduction {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  max-width: 1200px;
}

.introduction {
  padding: 3rem 0rem 2rem;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 2rem;
}
@media screen and (min-width: 600px) {
  .introduction {
    backdrop-filter: blur(5px);
    padding: 5.4rem 4rem 4rem;
  }
}
.introduction .text-component {
  margin-bottom: 0;
}
.introduction .text-component h3 {
  font-size: 2.25rem;
  margin-bottom: 2rem;
}

.card {
  background-color: #ffffff;
  padding: 0;
  position: relative;
  transition: background-color 0.2s ease;
}
.card:hover {
  background-color: #ffffff;
}
.card:hover .card__date-folder {
  border-color: #292A2D;
}
.card:focus-within {
  background-color: #ffffff;
}
.board .card {
  padding: 2rem 1.35rem 2.4rem;
  height: 100%;
  background-color: #f5f5f5;
}
@media screen and (min-width: 600px) {
  .board .card {
    background-color: #ffffff;
  }
}
.card__image {
  position: relative;
  margin: 0 0 0.85em;
  max-width: 100%;
  height: 0;
  padding-bottom: 66.6666666667%;
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
}
.card__image:empty {
  background: #f5f5f5 url("data:image/svg+xml;utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 173 147.1' width='191' height='162' xml:space='preserve' fill='%23e9e9e9'%3E%3Cpath d='M151.2 26.4l8-8.2-6.2-12-10.5 9.4c-.3-3.8-1.2-8.2-4.4-15.6h-.1c-6 2.3-6.7 1.7-10.3 3.6 2.8 11.6 1.2 18.7-2.6 29.3l-7.8 7.8c-.4-3.8-1.8-8.5-4.9-15.7-6 2.3-6.3 1.4-9.9 3.3-.6 10.4.8 19.5-3 30.2l-13 13-13-13c-3.8-10.7-2.3-19.8-3-30.2-3.6-1.9-3.9-1-9.9-3.3-3.1 7.2-4.5 11.8-4.9 15.7l-7.8-7.8c-3.8-10.6-5.3-17.7-2.6-29.3C41.7 1.7 41 2.3 35 0h-.1c-3.2 7.4-4.2 11.8-4.4 15.6L20 6.2l-6.2 12 8 8.2c-5.2.2-15.9.1-21.8 3.2 3.5 5.5 3.2 6.4 5.1 10.1 10.4.6 18.8-1.2 29.4 2.6l8.1 8.1c-4.2.7-9.3 2.6-16.9 5.9 2.3 6 .9 4.8 2.8 8.4 10.4.6 20.9-.7 31.6 3.1l15.1 15.1-30.8 30.9c-.4-4.2-1.9-9.3-5.1-16.8-6 2.3-6.4 3.2-10.1 5.1-.6 10.4 1.2 18.8-2.6 29.4l-5.1 5.1c4 4.9 8.1 9 12.8 10.5l5.7-6.5c10.7-3.8 18.8-2.9 29.2-3.6 1.9-3.6 3.1-3.1 5.4-9.1-7.5-3.2-12.5-4.7-16.8-5.1l28.7-28.7 28.7 28.7c-4.2.4-9.3 1.9-16.8 5.1 2.3 6 3.5 5.5 5.4 9.1 10.4.6 18.5-.2 29.2 3.6l5.7 6.5c4.6-1.5 8.8-5.6 12.8-10.5l-5.1-5.1c-3.8-10.7-2-19.1-2.6-29.4-3.6-1.9-4-2.8-10.1-5.1-3.2 7.5-4.7 12.6-5.1 16.8L97.8 82.9l15.1-15.1c10.7-3.8 21.2-2.5 31.6-3.1 1.9-3.6.5-2.4 2.8-8.4-7.6-3.3-12.7-5.3-16.9-5.9l8.1-8.1c10.7-3.8 19.1-2 29.4-2.6 1.9-3.6 1.6-4.5 5.1-10.1-5.9-3.1-16.5-3-21.8-3.2z'/%3E%3C/svg%3E") no-repeat center center;
  transition: background-color 0.3s ease-in-out;
}
.card__image img,
.card__image svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease-in-out;
  transform: scale(1);
}
.card__title {
  margin-top: 1.5rem;
  line-height: 1.25;
  margin-bottom: 0.75em;
  font-size: 1.0625rem;
  font-weight: 500;
  color: inherit;
  padding-left: 1rem;
  padding-right: 1rem;
}
.board .card__title {
  margin-top: 0.25rem;
}
.card__description {
  font-size: 0.75rem;
  line-height: 1.5;
  margin-top: 1.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.card__date-folder {
  position: relative;
  z-index: 25;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  font-size: 0.75rem;
  font-weight: bold;
  border-bottom: 1px solid #f2f3ef;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
}
.card__date-folder a,
.card__date-folder a:link,
.card__date-folder a:visited {
  color: #d90014;
}
.card__date-folder > *:not(:last-child)::after {
  content: "•";
  margin: 0 0.5rem;
  color: #252c10;
}
.board .card__date-folder {
  border-bottom: none;
}
.card__dates {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.card__dates .card__date {
  margin: 0;
}
.card__dates .card__date:not(:last-child)::after {
  content: " - ";
  margin: 0 0.5rem;
}
.card__dates .card__date::before {
  content: normal !important;
}
.card__date {
  margin: 0.2em 0 1em 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #d90014;
}
.card__link.-cover-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}
.card__link:hover, .card__link:focus {
  color: inherit;
}
.card .info-hidden,
.card .info-concept {
  display: inline-flex;
}
.card .info-hidden::before,
.card .info-concept::before {
  top: 1px;
}
@media screen and (min-width: 1024px) {
  .card .card__date-folder,
  .card .card__title,
  .card .card__description {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.card__link,
.card__link:link,
.card__link:visited {
  color: #252c10;
  text-decoration: underline;
}

.card__link:hover,
.card__link:focus,
.card__link:active {
  color: #d90014;
  text-decoration: none;
}
.card__link:hover .card__image:empty,
.card__link:focus .card__image:empty,
.card__link:active .card__image:empty {
  background-color: #bdbdbd;
}
.card__link:hover .card__image img,
.card__link:hover .card__image svg,
.card__link:focus .card__image img,
.card__link:focus .card__image svg,
.card__link:active .card__image img,
.card__link:active .card__image svg {
  transform: scale(1.1);
}

.board-card {
  background-color: #ffffff;
  padding: 5%;
  position: relative;
  box-shadow: 0 0 0 2px #ededed;
  min-height: 16em;
}
@media screen and (min-width: 650px) {
  .board-card {
    padding: 2em;
  }
}
@media screen and (min-width: 1024px) {
  .board-card {
    padding: 3em 3vw;
  }
}
.board-card__date {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 1.375rem;
  font-weight: bold;
  color: #292A2D;
  margin: 0 0 0;
}
.board-card__date.-to {
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0 1.25em;
}
.board-card__title {
  margin-top: 0;
  margin-bottom: 0.33em;
  font-size: 1.375rem;
  color: inherit;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  line-height: 1.3636363636;
}

.board-card__link,
.board-card__link:link,
.board-card__link:visited {
  color: #d90014;
  text-decoration: underline;
}

.board-card__link:hover,
.board-card__link:focus,
.board-card__link:active {
  color: #252c10;
  text-decoration: none;
}

.section.-abo .centered {
  max-width: 780px;
}

.abo {
  text-align: center;
}
.abo__form .form__section {
  align-items: center !important;
}
.abo__form .label {
  position: absolute !important;
  left: -9999rem !important;
  top: auto;
  outline: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}
.abo__form .input {
  background-color: #ffffff;
  border-color: #292A2D;
  color: #292A2D;
  min-height: 55px;
  text-align: center;
  box-shadow: 0 0 0;
}
.abo__form .gdpr-agreement {
  margin: 0;
}

.section.-homepage-tiles {
  position: relative;
  z-index: 20;
  padding-top: 1.8rem;
}

.homepage-tiles {
  position: relative;
}
.homepage-tiles .list {
  display: grid;
}
@media screen and (min-width: 1px) and (max-width: 400px) {
  .homepage-tiles .list {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
  }
}
@media screen and (min-width: 400px) and (max-width: 600px) {
  .homepage-tiles .list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
  }
}
@media screen and (min-width: 600px) and (max-width: 1024px) {
  .homepage-tiles .list {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
  }
}
@media screen and (min-width: 1023px) {
  .homepage-tiles .list {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1.75rem;
    gap: 1.75rem;
  }
}
.homepage-tiles .list__item {
  position: relative;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5px);
  border: 2px solid #e4e4e4;
  text-align: center;
  border-radius: 12px;
  padding: 1rem 1rem 1.3rem;
  margin-bottom: 0;
}
.homepage-tiles .vismo-left-buttons-together li[data-group-element-id]::before {
  content: normal !important;
}
.homepage-tiles .vismo-left-buttons-together li[data-group-element-id] .vismo-checkbox,
.homepage-tiles .vismo-left-buttons-together li[data-group-element-id] .vismo-context-button {
  z-index: 30;
  left: 0 !important;
}
.homepage-tiles .vismo-left-buttons-together li[data-group-element-id] .vismo-checkbox {
  top: 1.75rem;
  background-color: #eee;
  width: 20px;
  padding: 0 0.3rem 0.2rem;
  box-sizing: content-box;
  border-radius: 0 0 5px 5px;
  font-size: 1rem;
}

.homepage-tile__image {
  margin: 0 0 0;
}
.homepage-tile__image svg,
.homepage-tile__image img {
  max-width: 45px;
  fill: #bababa;
}
.homepage-tile__title {
  font-size: 1.0625rem;
  color: inherit;
}
.homepage-tile__description {
  margin: 0;
  color: #656565;
  font-size: 0.75rem;
}
.homepage-tile__link {
  display: flex;
  flex-direction: column;
}
.homepage-tile__link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.homepage-tile__link, .homepage-tile__link:link, .homepage-tile__link:visited {
  color: #252c10;
  text-decoration: none;
}
.homepage-tile__link:hover, .homepage-tile__link:focus, .homepage-tile__link:active {
  color: #d90014;
}
.homepage-tile__link:hover svg, .homepage-tile__link:focus svg, .homepage-tile__link:active svg {
  fill: #d90014;
}

@media screen and (max-width: 599px) {
  .section.-news .list,
  .section.-homepage-events[data-liteslider-js] .list {
    transform: translateX(0) !important;
  }
  .section.-news .news__buttons,
  .section.-news .homepage-events__buttons,
  .section.-homepage-events[data-liteslider-js] .news__buttons,
  .section.-homepage-events[data-liteslider-js] .homepage-events__buttons {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .section.-news,
  .section.-homepage-events {
    width: 100%;
    max-width: calc(100% - 100px);
    margin: 0 auto;
  }
  .section.-news .centered,
  .section.-news .dev-element-block,
  .section.-homepage-events .centered,
  .section.-homepage-events .dev-element-block {
    position: relative;
    z-index: 20;
  }
  .section.-news .news,
  .section.-news .homepage-events,
  .section.-homepage-events .news,
  .section.-homepage-events .homepage-events {
    overflow: auto;
  }
  .section.-news .news__buttons,
  .section.-news .homepage-events__buttons,
  .section.-homepage-events .news__buttons,
  .section.-homepage-events .homepage-events__buttons {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 33%;
    transform: translateY(-50%);
    left: -3rem;
    right: -3rem;
  }
  .section.-news .news__buttons .liteslider-arrow-disabled,
  .section.-news .homepage-events__buttons .liteslider-arrow-disabled,
  .section.-homepage-events .news__buttons .liteslider-arrow-disabled,
  .section.-homepage-events .homepage-events__buttons .liteslider-arrow-disabled {
    opacity: 0.25;
  }
  .section.-news .homepage-events__buttons,
  .section.-homepage-events .homepage-events__buttons {
    top: 50%;
  }
  .section.-news .list,
  .section.-homepage-events .list {
    overflow: visible;
    white-space: nowrap;
    display: flex;
    align-items: stretch;
    margin: 0 -1.5rem;
  }
  .section.-news .list li,
  .section.-homepage-events .list li {
    box-sizing: border-box;
    display: block;
    line-height: 1.1;
    white-space: normal;
    flex-shrink: 0;
    width: 100%;
    padding: 0 1.5rem;
    margin: 0;
    position: relative;
  }
}
@media screen and (min-width: 600px) and (min-width: 700px) {
  .section.-news .list li,
  .section.-homepage-events .list li {
    width: 50%;
  }
}
@media screen and (min-width: 600px) and (min-width: 1000px) {
  .section.-news .list li,
  .section.-homepage-events .list li {
    width: 25%;
  }
}
@media screen and (min-width: 600px) {
  .section.-news[data-liteslider-js] .news,
  .section.-news[data-liteslider-js] .homepage-events,
  .section.-homepage-events[data-liteslider-js] .news,
  .section.-homepage-events[data-liteslider-js] .homepage-events {
    overflow: hidden;
  }
  .section.-news[data-liteslider-js] .list,
  .section.-homepage-events[data-liteslider-js] .list {
    transform: translateX(0);
    transition: transform 0.5s cubic-bezier(0.61, 0.43, 0.15, 0.94);
  }
}
.blender {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: none;
  background: #292A2D url(/images/local/blender/v-202411080924/0.jpg) no-repeat 50% 50%/cover;
}
@media screen and (min-width: 600px) {
  .blender {
    display: block;
  }
  .blender__container {
    padding-bottom: 550px;
  }
  .blender__container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: transparent url(/images/local/v-202411080924/blender-shadow-w.png) repeat-x 0 100%;
    height: 294px;
    z-index: 30;
  }
  .blender__buttons {
    display: flex;
  }
}
@media screen and (min-width: 1280px) {
  .blender__container {
    padding-bottom: 44.53125%;
  }
}
@media screen and (min-width: 1920px) {
  .blender__container {
    padding-bottom: 855px;
  }
}
.blender__container {
  position: relative;
}
.blender__buttons {
  position: absolute;
  z-index: 15;
  right: 3%;
  bottom: 2rem;
  display: none;
}
.blender__buttons[data-blender-buttons] {
  right: 50%;
  transform: translateX(50%);
}
.blender__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 0;
  background: transparent none;
  box-shadow: none;
  width: 50px;
  height: 50px;
  background-color: #bfbfbf;
  cursor: pointer;
}
.blender__button--active {
  background-color: #dddddd;
}
.blender__button svg {
  fill: #292A2D;
}
.blender__button:hover, .blender__button:focus, .blender__button:active {
  background-color: #252c10;
  color: #ffffff;
}
.blender__button:hover svg, .blender__button:focus svg, .blender__button:active svg {
  fill: #ffffff;
}
@keyframes blend-in {
  from {
    display: block;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}
.blender__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.blender__item.-animating {
  animation: blend-in 800ms ease-out;
  animation-fill-mode: forwards;
}
.blender__title, .blender__description {
  position: absolute;
  right: 0em;
  bottom: 4%;
  z-index: 51;
}
.blender__description {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35.8em;
  padding: 1rem 1em 1rem 6%;
  max-width: 55%;
  height: 140px;
}
.blender__description a,
.blender__description a:link,
.blender__description a:visited {
  color: #ffffff;
  font-size: 1.3125rem;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  margin: 0 0 0.25em;
  text-decoration: underline;
}
.blender__description a:hover,
.blender__description a:focus,
.blender__description a:active {
  text-decoration: none;
}
.blender__description strong {
  font-size: 1.3125rem;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  margin: 0 0 0.25em;
}
.blender__description span {
  font-size: 1rem;
}
.blender__description:before {
  position: absolute;
  content: "";
  top: 0px;
  bottom: 0px;
  left: 0;
  right: -40px;
  background-color: rgba(0, 0, 0, 0.75);
  transform: skew(-14.5deg);
  z-index: -1;
}

.figure__caption {
  padding: 0.725em 10px 1.5em;
  border-bottom: 1px #cccccc solid;
}
.figure__caption.-photo, .figure__caption.-video, .figure__caption.-illustration {
  padding-left: 30px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 10px 0.72em;
  background-size: 20px auto;
}
@media screen and (min-width: 800px) {
  .figure__caption.-photo, .figure__caption.-video, .figure__caption.-illustration {
    padding-left: 50px;
    background-position: 10px 0.48em;
    background-size: auto;
  }
}
.figure__caption.-photo {
  background-image: icon("camera", #ccc);
}
.figure__caption.-video {
  background-image: icon("movie", #ccc);
}
.figure__caption.-illustration {
  background-image: icon("picture", #ccc);
}

.tabs-sections [role=tabsection] {
  padding: 0;
}

.tabs {
  display: none;
}
.tabs [role=tab] {
  font-size: 2.125rem;
  line-height: 1.1;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.tabs[data-tabs] > p {
  display: none;
}

.tablist[role=tablist] {
  list-style-type: none;
  margin: 0 0 2.5rem 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.tablist[role=tablist] .tablist__item {
  padding: 0 1rem;
  margin: 0;
  display: block;
  background-color: transparent;
  color: #878787;
  cursor: pointer;
}
.tablist[role=tablist] .tablist__item:hover, .tablist[role=tablist] .tablist__item:focus, .tablist[role=tablist] .tablist__item:active {
  text-decoration: underline;
}
.tablist[role=tablist] .tablist__item:hover, .tablist[role=tablist] .tablist__item:focus {
  outline: 0;
}
.tablist[role=tablist] .tablist__item[aria-selected=true] {
  cursor: default;
  font-size: 2.75rem;
  background-color: transparent;
  color: #252c10;
  font-weight: 700;
  text-decoration: none;
}

@media screen and (min-width: 650px) {
  .tabs {
    display: block;
  }
}
@media screen and (min-width: 650px) and (min-width: 651px) {
  .tabs-sections [role=tabsection] h2.element-type-heading,
  .tabs-sections [role=tabsection] h3.element-type-heading {
    position: absolute;
    left: -9999em;
    top: 0;
    width: 0;
    height: 0;
    line-height: 0;
    overflow: hidden;
    outline: 0;
  }
}
@media screen and (min-width: 650px) {
  .tabs-sections [role=tabsection][aria-hidden=true] {
    display: none;
  }
}
.section.-banners {
  padding: 0;
}
.section.-banners .text-component {
  padding: 4rem 0;
  margin-bottom: 0;
}

@media screen and (min-width: 600px) {
  .warning-container {
    position: absolute;
    bottom: 8rem;
    right: 0;
    z-index: 60;
    max-width: 33rem;
    padding: 2% 4% 2%;
    background-color: #fff;
  }
}

.warning__title {
  display: inline;
  font-size: 1rem;
}
.warning__description {
  font-size: 0.875rem;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

/*
* HIGHEST PRIORITY
*/
.home {
  position: relative;
  z-index: 40;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 600px) {
  .home {
    margin-top: -150px;
  }
}
@media screen and (min-width: 800px) {
  .home::before {
    position: absolute;
    content: "";
    right: 5vw;
    top: 250px;
    max-width: 290px;
    background: transparent url(/images/local/icons/v-202411080924/homepage-tiles-arrows.svg) no-repeat 100% 0/contain;
    width: 330px;
    height: 330px;
    z-index: -1;
  }
}
@media screen and (min-width: 1360px) {
  .home {
    margin-top: -250px;
  }
}

.section {
  padding: 1rem 0;
}
.section .element-type-heading {
  font-size: 2.25rem;
  color: #292A2D;
  text-align: center;
  margin-bottom: 1em;
}
@media screen and (min-width: 800px) {
  .section {
    padding: 0 0 2rem;
  }
}
@media screen and (min-width: 1600px) {
  .section {
    padding: 0.1rem 0 4rem;
  }
}

.anchor {
  position: relative;
  top: -20px;
}
@media screen and (min-width: 1024px) {
  .anchor {
    top: -140px;
  }
}

.homepage__anchors {
  position: fixed;
  top: 50%;
  transform: translate(100%, -50%);
  right: 0;
  z-index: 50;
  background-color: #d90014;
  color: #fff;
  padding: 3rem 3.6rem 2.5rem;
  transition: transform 0.3s ease-in-out;
  display: none;
}
@media screen and (min-width: 600px) {
  .homepage__anchors {
    display: block;
  }
}
.homepage__anchors .list {
  line-height: 1.6;
}
.homepage__anchors .list__item {
  margin-bottom: 0;
}
.homepage__anchors .list__item.-go-to-head {
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  margin-left: 0.4rem;
}
.homepage__anchors .list__item.-go-to-head .list__link {
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}
.homepage__anchors .list__item.-go-to-head .list__link::before {
  content: url(/images/local/icons/v-202411080924/anchor-arrow.png);
}
.homepage__anchors .list__link {
  text-decoration: none;
}
.homepage__anchors .list__link.active {
  font-weight: 700;
}
.homepage__anchors .list__link:hover, .homepage__anchors .list__link:focus, .homepage__anchors .list__link:active {
  text-decoration: underline;
}
.homepage__anchors:hover, .homepage__anchors:focus, .homepage__anchors:active {
  transform: translate(0%, -50%);
}
.homepage__anchors:hover .homepage__anchors-trigger::before, .homepage__anchors:focus .homepage__anchors-trigger::before, .homepage__anchors:active .homepage__anchors-trigger::before {
  transform: rotate(0deg);
}
.homepage__anchors a,
.homepage__anchors a:link,
.homepage__anchors a:visited {
  color: #fff;
}
.homepage__anchors-trigger {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 100%;
  top: 50%;
  width: 50px;
  height: 93px;
  transform: translateY(-50%);
  background: transparent url(/images/local/icons/v-202411080924/anchor-left-bubble.png) no-repeat center center;
}
.homepage__anchors-trigger::before {
  transform: rotate(180deg);
  content: url(/images/local/icons/v-202411080924/anchor-arrow-w.png);
  transition: transform 0.3s ease-in-out;
}

.section.-news {
  padding-top: 0.5rem;
}
.section.-news h2.element-type-heading {
  color: #d90014;
}

.section.-chosen {
  position: relative;
  padding-top: 0.5rem;
}
.section.-chosen::before {
  position: absolute;
  content: "";
  top: -15%;
  right: 3%;
  width: 347px;
  height: 171px;
  display: inline-block;
  background: transparent url(/images/local/v-202411080924/zpravodaj-bg.svg) no-repeat center center/contain;
  z-index: -1;
}

.section.-discovery {
  position: relative;
  z-index: 20;
  padding-top: 6.3rem;
  padding-bottom: 4rem;
}
.section.-discovery h2.element-type-heading {
  font-size: 2.25rem;
  color: #ffffff;
  margin-bottom: 0.65em;
}
.section.-discovery .discovery {
  position: relative;
  color: #ffffff;
  font-size: 1.1875rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem 0;
}
.section.-discovery .discovery-block {
  position: relative;
  background-color: #d90014;
  padding: 2rem 4% 0.5rem;
  border-radius: 28px;
}
.section.-discovery .discovery-block.-edeska {
  display: flex;
  align-items: center;
  text-align: center;
  background: #252c10 url(/images/local/v-202411080924/board-bg.svg) no-repeat 100% 100%;
  background-size: 14rem;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.section.-discovery .discovery-block.-edeska .dev-element-block {
  flex-grow: 1;
}
.section.-discovery .discovery-block.-edeska h2.element-type-heading {
  max-width: 9em;
  margin: 0 auto 2rem;
}
.section.-discovery .discovery-block.-edeska .list__item {
  display: flex;
  justify-content: center;
}
.section.-discovery .discovery-block.-edeska a,
.section.-discovery .discovery-block.-edeska a:link,
.section.-discovery .discovery-block.-edeska a:visited {
  color: #252c10;
  background-color: #ffffff;
}
.section.-discovery .discovery-block.-edeska::after {
  content: normal;
}
@media screen and (min-width: 1024px) {
  .section.-discovery .discovery-block.-edeska {
    background-position: 130% 120%;
    background-size: 22rem;
  }
}
@media screen and (min-width: 800px) {
  .section.-discovery .discovery-block {
    padding: 4.5rem 6rem 1.75rem;
  }
  .section.-discovery .discovery-block::after {
    position: absolute;
    left: 50%;
    top: 0%;
    content: "";
    transform: translate(-50%, -50%);
    width: 129px;
    height: 111px;
    background: transparent url(/images/local/v-202411080924/arrows-w.svg) no-repeat center center;
  }
}
@media screen and (min-width: 1024px) {
  .section.-discovery .discovery {
    grid-template-columns: 1.4fr 1fr;
    gap: 4rem;
  }
}
@media screen and (min-width: 1300px) {
  .section.-discovery .discovery {
    gap: 8rem;
  }
}
.section.-discovery .discovery__more-button {
  background-color: #252c10;
}
.section.-discovery .discovery__more-button:hover, .section.-discovery .discovery__more-button:focus, .section.-discovery .discovery__more-button:active {
  text-decoration: underline;
}

.section.-board {
  position: relative;
  padding-top: 3rem;
}
.section.-board::before, .section.-board::after {
  position: absolute;
  content: "";
}
@media screen and (min-width: 800px) {
  .section.-board {
    padding-top: 7.4rem;
  }
  .section.-board::before {
    top: -45px;
    left: 0;
    width: 268px;
    height: 224px;
    background: transparent url(/images/local/v-202411080924/board-bg.svg) no-repeat center center;
  }
}
@media screen and (min-width: 800px) and (min-width: 1360px) {
  .section.-board::before {
    top: -135px;
    left: 7.5%;
    width: 328px;
    height: 334px;
  }
}
@media screen and (min-width: 800px) {
  .section.-board::after {
    top: 50%;
    right: 0;
    width: 327px;
    height: 491px;
    background: transparent url(/images/local/v-202411080924/books.png) no-repeat center center;
    transform: translateY(-50%);
  }
}
.section.-board .centered {
  position: relative;
  z-index: 20;
  max-width: 1200px;
}