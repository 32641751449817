.section.-abo {
	.centered {
		max-width: 780px;
	}
}

.abo {
	text-align: center;

	&__form {
		.form__section {
			align-items: center !important;
		}

		.label {
			position: absolute !important;
			left: -9999rem !important;
			top: auto;
			outline: 0;
			width: 0;
			height: 0;
			overflow: hidden;
		}

		.input {
			background-color: $color-light;
			border-color: $color-dark;
			color: $color-dark;
			min-height: 55px;
			text-align: center;
			box-shadow: 0 0 0;
		}

		.gdpr-agreement {
			margin: 0;
		}
	}
}
