.homepage-tiles-discovery-wrapper{
	background: #ffffff url(/images/local/v-202411080924/homepage-events-bg.jpg) no-repeat center top / cover;
	max-width: 1920px;
	width: 100%;
	margin: 0 auto;
}

.homepage-events {
	.list {
		padding-top: 2rem;
		@include min(600px){
			margin: 0;
			//@include grid(500px 3 2rem, 1024px 4 3rem);
		}
	}
}

.homepage-event {
	position: relative;
	background-color: $color-light;
	border-radius: 12px;
	overflow: hidden;
	padding: 0;
	height: 100%;
	transition: box-shadow .3s ease-in-out;
	&__image {
		position: relative;
		margin: 0 0;
		overflow: hidden;
		height: 0;
		//padding-bottom: ratio(3, 2);
		padding-bottom: 140%;

		&:empty {
			transition: background-color .4s ease-in-out;
			background: #f5f5f5 encode-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 173 147.1" width="191" height="162" xml:space="preserve" fill="#e9e9e9"><path d="M151.2 26.4l8-8.2-6.2-12-10.5 9.4c-.3-3.8-1.2-8.2-4.4-15.6h-.1c-6 2.3-6.7 1.7-10.3 3.6 2.8 11.6 1.2 18.7-2.6 29.3l-7.8 7.8c-.4-3.8-1.8-8.5-4.9-15.7-6 2.3-6.3 1.4-9.9 3.3-.6 10.4.8 19.5-3 30.2l-13 13-13-13c-3.8-10.7-2.3-19.8-3-30.2-3.6-1.9-3.9-1-9.9-3.3-3.1 7.2-4.5 11.8-4.9 15.7l-7.8-7.8c-3.8-10.6-5.3-17.7-2.6-29.3C41.7 1.7 41 2.3 35 0h-.1c-3.2 7.4-4.2 11.8-4.4 15.6L20 6.2l-6.2 12 8 8.2c-5.2.2-15.9.1-21.8 3.2 3.5 5.5 3.2 6.4 5.1 10.1 10.4.6 18.8-1.2 29.4 2.6l8.1 8.1c-4.2.7-9.3 2.6-16.9 5.9 2.3 6 .9 4.8 2.8 8.4 10.4.6 20.9-.7 31.6 3.1l15.1 15.1-30.8 30.9c-.4-4.2-1.9-9.3-5.1-16.8-6 2.3-6.4 3.2-10.1 5.1-.6 10.4 1.2 18.8-2.6 29.4l-5.1 5.1c4 4.9 8.1 9 12.8 10.5l5.7-6.5c10.7-3.8 18.8-2.9 29.2-3.6 1.9-3.6 3.1-3.1 5.4-9.1-7.5-3.2-12.5-4.7-16.8-5.1l28.7-28.7 28.7 28.7c-4.2.4-9.3 1.9-16.8 5.1 2.3 6 3.5 5.5 5.4 9.1 10.4.6 18.5-.2 29.2 3.6l5.7 6.5c4.6-1.5 8.8-5.6 12.8-10.5l-5.1-5.1c-3.8-10.7-2-19.1-2.6-29.4-3.6-1.9-4-2.8-10.1-5.1-3.2 7.5-4.7 12.6-5.1 16.8L97.8 82.9l15.1-15.1c10.7-3.8 21.2-2.5 31.6-3.1 1.9-3.6.5-2.4 2.8-8.4-7.6-3.3-12.7-5.3-16.9-5.9l8.1-8.1c10.7-3.8 19.1-2 29.4-2.6 1.9-3.6 1.6-4.5 5.1-10.1-5.9-3.1-16.5-3-21.8-3.2z"/></svg>') no-repeat center center;

			@include link-over(&) {
				background-color: gray;
			}
		}

		img {
			position: absolute;
			@include fill();
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transition: transform .3s ease-in-out;
			transform: scale(1);
		}
	}

	&__body {
		@include flex(df, aic);
		gap: 1rem;
	}

	&__date {
		&-fullday {
			position: absolute;
			z-index: 20;
			top: 2rem;
			left: 2rem;
			max-width: calc(100% - 4rem);
			font-size: rem(18px);
			font-weight: 700;
			padding: 0.95rem 1.5rem;
			border-radius: .5rem;
			background-color: $color-brand;
			color: $color-light;
		}
	}

	&__link {
		position: relative;
		display: flex;
  		flex-direction: column;
		margin-bottom: 0;
		&.-cover-link {
			&::after {
				@include fill;
				content: "";
			}
		}

		@include link(&) {
			color: $color-light;
		}
		@include link-over(&) {
			.homepage-event__title{
				text-decoration: underline;
			}
		}
	}

	&__title {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 105px;
		z-index: 15;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0.5rem 1rem;
		font-size: rem(17px);
		margin: 0 0 0;
		font-weight: 700;
		color: inherit;
		text-align: center;
		&::before{
			position: absolute;
			content: "";
			bottom: 0;
			left: 0;
			right: 0;
			background: transparent url(/images/local/v-202411080924/homepage-events-shadow.png) repeat-x 0 0;
			top: -5rem;
			z-index: -1;
		}
	}
	&__description{
		margin: 0;
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	.event__status {
		padding: 0 6%;
	}

	&__types {
		position: relative;
		z-index: 20;
		margin-top: 1rem;
		@include flex(df, fww, jcc);
		gap: 0 1.5rem;
	}

	&__type {
		display: inline-block;

		&__link {
			font-size: rem(12px);
			font-weight: 700;

			@include link(&) {
				color: #656565;
			}

			@include link-over(&) {

			}
		}
	}
	@include link-over(&){
		box-shadow: 0px 20px 10px -20px #111;
		.homepage-event__image{
			img{
				transform: scale(1.1);
			}
		}
	}
}
